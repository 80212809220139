
import { defineComponent, onMounted } from "vue";
import DebitNotesTable from "@/components/admin/finance/DebitNotesTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "admin-debit-notes",
  components: {
    DebitNotesTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Debit Notes", ["Finance"]);
    });
  },
  created() {
    //Set page title
    document.title = "Debit Notes List | " + process.env.VUE_APP_TITLE;
  },
});
